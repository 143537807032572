<template lang="html">

  <div v-if="this.$parent.$parent.status === 'accept'" class="embed-responsive embed-responsive-4by3">
    <iframe
      class="embed-responsive-item"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2747.225465636737!2d3.980601415672862!3d46.48384777284777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f3df22731b601b%3A0xc7ea3a8545034423!2sLEFLOCH%20M%C3%A9tal%20Concept!5e0!3m2!1sen!2sfr!4v1672332967653!5m2!1sen!2sfr"
    ></iframe>
  </div>
  <div v-else >
          <img class="alternative-img-maps" src="/images/ConsentMaps.png" alt="Apercu video floue">
        </div>
</template>
<script>
export default {
  name: "Map",
};
</script>
<style lang="css">
@media (min-width: 576px) {
  .embed-ex {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

@media (max-width: 576px) {
  .embed-responsive {
    width: 100% !important;
  }
}

.embed-ex {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}
</style>
<style lang="scss">
.embed-responsive {
  position: relative;
  display: block;
  border-radius: 10px;
  width: 65%;
  max-height: 500px;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.alternative-img-maps {
    width: 100%;
    height: auto;
    margin-bottom: 2%;
    max-height: 500px;
    overflow: hidden;
    border-radius: 10px;
  }

.embed-responsive-4by3 {
  &::before {
    padding-top: percentage(3 / 4);
  }
}
</style>
