<template lang="">
   <main role="main" class="inner cover cover-container informations">
      <h1 class="cover-heading usefont"> <font-awesome-icon class="clear"
          :icon="['fas', 'lightbulb']"
          size="1x"
        ></font-awesome-icon> Une idée ? Une demande de devis ? </h1>
       <div class="lead usefont cover-heading">
         <h2>Contactez nous !</h2>
       </div>
      <p class="lead">
         LEFLOCH Métal Concept
      </p>
        <font-awesome-icon
          :icon="['fas', 'map-marked-alt']"
          size="1x"
        ></font-awesome-icon> 3 Lotissement les Peupliers, 71160 Digoin. <br> <br>
        <font-awesome-icon
          :icon="['fas', 'store']"
          size="1x"
        ></font-awesome-icon> Ouvert du <b>Lundi au Jeudi</b> de <b>9h à 12h</b> et de <b>14h à 18h</b> <br> 
        Le <b>Vendredi</b> de <b>9h à 12h</b> et de <b>13h30 à 17h30</b> <br> 
        Le <b>2ème Samedi du mois</b> de <b>9h à 12h</b> <br> 
        Et sur rendez-vous.<br>  <br>
        <font-awesome-icon
          :icon="['fas', 'envelope']"
          size="1x"
        ></font-awesome-icon> <a href="mailto:contact@lefloch-metalconcept.fr">contact@lefloch-metalconcept.fr</a>  
         <p><font-awesome-icon
          :icon="['fas', 'phone-alt']"
          size="1x"
        ></font-awesome-icon> <a href="tel:+33385811454">+33(0)385811454</a></p>
    </main>
</template>
<script>
export default {
  name: "aboutContent",
};
</script>
<style lang="css">
.informations {
  text-align: left !important;
}
</style>