<template>
  <div :class="this.$parent.status === 'accept' ? 'myflex_wMap cover min-pb' : 'myflex cover min-pb'">
    <Map />
    <aboutContent/>
  </div>
</template>
<script>
import Map from "/src/components/Map.vue";
import aboutContent from "/src/components/AboutContent.vue"
export default {
  name: 'About',
  components: {
    Map,
    aboutContent
  },
  metaInfo: {
      title: 'À propos',
      titleTemplate: '%s | LEFLOCH Métal Concept'
    }
};
</script>
<style lang="css">

.myflex_wMap, .myflex {
    display: flex !important;
    min-height: fit-content;
    flex-direction: row;
}

@media (max-width: 1400px) {
  .myflex {
    flex-direction: column;
  }
  .informations {
    padding-top: 10px;
  }
}

@media (max-width: 576px) {
  .myflex_wMap {
    display: block!important;;
  }
  .informations {
    padding-top: 10px;
  }
}
.min-pb {
  padding-bottom: min(25px, 5%);
}
</style>
